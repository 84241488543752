// slick
import $ from 'jquery/dist/jquery'
import 'slick-carousel/slick/slick'
import debounce from 'lodash/debounce'
import throttle from 'lodash/throttle'

var watch;
var locations;

$(document).ready(function() {

  // yearbook code
  $('#yearbook-code').on('click', function() {
    var code = prompt("Please enter your demo code", "");
    if (code == null || code == "") {
      // do nothing
    }
    else if (code == 'showmemydemo') {
      // redirect
      window.location.href = "http://www.schoolannualonline.com/SchoolAnnualOnline/";
    }
    else {
      alert("Sorry that code is not recognized");
    }
  });

  // submit contact form
  $('#frm-info').submit(function(e) {
    e.preventDefault();
    var paramObj = {};
    $.each($('#frm-info').serializeArray(), function(_, kv) {
      if (paramObj.hasOwnProperty(kv.name)) {
        paramObj[kv.name] = $.makeArray(paramObj[kv.name]);
        paramObj[kv.name].push(kv.value);
      }
      else {
        paramObj[kv.name] = kv.value;
      }
    });
    console.log(paramObj);
    submitContactForm(paramObj);
  });

  // throttle
  watch = false;
  setTimeout(function(){
    watch = true;
  }, 3000);

  // get page locations
  locations = getLocations();
  $('#school-slider').on('init', function(event, slick){
    locations = getLocations();
  });

  // update locations on resize
  $(window).on('resize', debounce(function() {
    locations = getLocations();
  }, 300));

  // watch scrolling
  $(document).on('scroll', throttle(function(){
    var width = $(document).width();
    var scrollShift = 200;
    if (width > 768) {
      scrollShift = 350;
    } else if (width > 1024) {
      scrollShift = 500;
    }
    var scroll = $(window).scrollTop() + scrollShift // arbitrary constant;
    if (watch) {
      if (scroll < locations.about) {
        history.replaceState('', '', ' ');
        $('#scroll-to-top').removeClass('show');
      }
      else if (scroll > locations.about && scroll < locations.schoolPhotos) {
        history.replaceState('', '', '#about');
        $('#scroll-to-top').addClass('show');
      }
      else if (scroll > locations.schoolPhotos && scroll < locations.events) {
        history.replaceState('', '', '#school-photos');
        $('#scroll-to-top').addClass('show');
      }
      else if (scroll > locations.events && scroll < locations.yearbooks) {
        history.replaceState('', '', '#events');
        $('#scroll-to-top').addClass('show');
      }
      else if (scroll > locations.yearbooks && scroll < locations.sports) {
        history.replaceState('', '', '#yearbooks');
        $('#scroll-to-top').addClass('show');
      }
      else if (scroll > locations.sports && scroll < locations.familyPortraits) {
        history.replaceState('', '', '#sports');
        $('#scroll-to-top').addClass('show');
      }
      else if (scroll > locations.familyPortraits && scroll < locations.contact) {
        history.replaceState('', '', '#family-portraits');
        $('#scroll-to-top').addClass('show');
      }
      else {
        history.replaceState('', '', '#contact');
        $('#scroll-to-top').addClass('show');
      }
    }
  }, 300));

  // desktop menu scrolling
  $('#scroll-menu > li > a').click(function(e) {
    e.preventDefault();
    var target = this.hash;
    scrollTo(target);
  });

  // mobile menu scrolling
  $('#mobile-menu-container > ul > li > a.scroll-link').click(function(e) {
    e.preventDefault();
    $('#mobile-menu-container').removeClass('open');
    $('#mobile-menu-button').removeClass('open');
    var target = this.hash;
    setTimeout(scrollTo.bind(null, target), 600); // time should match css transistion time
  });

  // contact links
  $('.contact-scroll').click(function(e) {
    e.preventDefault();
    scrollTo('#contact');
  })

  // scroll to top
  $('#scroll-to-top').click(function() {
    scrollTo('#top');
  });

  // school slick slider
  $('#school-slider').slick({
    dots: false,
    arrows: false,
    speed: 600,
    infinite: false,
    slidesToScroll: 2,
    slidesToShow: 2,
    autoplay: true,
    autoplaySpeed: 3000,
    appendDots: $('#school-dots'),
    mobileFirst: true,
    responsive: [{
      breakpoint: 576,
      settings: {
        dots: true,
        arrows: true,
        slidesToScroll: 2,
        slidesToShow: 2
      }
    }, {
      breakpoint: 756,
      settings: {
        dots: true,
        arrows: true,
        slidesToScroll: 3,
        slidesToShow: 3
      }
    }, {
      breakpoint: 992,
      settings: {
        dots: true,
        arrows: true,
        slidesToScroll: 4,
        slidesToShow: 4
      }
    }]
  });

});

// randomizer
$.fn.randomize = function(selector){
  var $elems = selector ? $(this).find(selector) : $(this).children(),
      $parents = $elems.parent();

  $parents.each(function(){
      $(this).children(selector).sort(function(){
          return Math.round(Math.random()) - 0.5;
      }).detach().appendTo(this);
  });

  return this;
}

// get section locations
function getLocations() {
  var locations = {};
  locations.about = $('#about').offset().top;
  locations.schoolPhotos = $('#school-photos').offset().top;
  locations.events = $('#events').offset().top;
  locations.yearbooks = $('#yearbooks').offset().top;
  locations.sports = $('#sports').offset().top;
  locations.familyPortraits = $('#family-portraits').offset().top;
  locations.contact = $('#contact').offset().top;
  return locations;
}

// smooth scrolling function
function scrollTo(target) {
  watch = false;
  var $target = $(target);
  //target = target.length ? target : $('[name=' + this.hash.slice(1) + ']');
  // Does a scroll target exist?
  if ($target.length) {
    // Only prevent default if animation is actually gonna happen
    $('html, body').animate({
      scrollTop: $target.offset().top
    }, 1000, function() {
      // Callback after animation
      // Must change focus!

      $target.focus();
      if ($target.is(":focus")) { // Checking if the target was focused
        return false;
      } else {
        $target.attr('tabindex','-1'); // Adding tabindex for elements not focusable
        $target.focus(); // Set focus again
      };
      // update hash
      if (target != '#top') {
        history.replaceState('', '', target);
        $('#scroll-to-top').addClass('show');
      }
      else {
        history.replaceState('', '', ' ');
        $('#scroll-to-top').removeClass('show');
      }
      watch = true;
    });
  }
}

function submitContactForm(f)
{
  var name = f.name.trim();
  var home_phone   = f.home_phone.trim();
  var email = f.email.trim();
  var interest = f.interest.trim();
  var message = f.message.trim();

  if (name == '') {
    say("Please enter your name.");
    $("input[name='name']").focus();
  }
  else if (email == '') {
    say("Please enter your email address.");
    $("input[name='email']").focus();
  }
  else if (!validEmail(email)) {
    say("Please verify that you have entered your email address correctly.");
    $("input[name='email']").focus();
  }
  else if (interest == 'Interested In...') {
	say("Please specify your reason for contacting Cassiday Studios.");
	$("input[name='interest']").focus();
  }
  else if (interest == 'In need of Customer Service Support') {
	say("You will now be redirected to the customer service portal");
	window.location.href = '/helpdesk.php?name=' + urlencode(name) + '&email=' + urlencode(email) + '&message=' + urlencode(message);
  }
  else if (message == '') {
    say("Please type a message to explain how we can help you.");
    $("input[name='message']").focus();
  }
  else {
    //say("Contacting server...");
    var args = 'priority=2&notify=on&subject=Customer+Service' + '&name=' + urlencode(name) + '&home_phone=' + urlencode(home_phone) + '&email=' + urlencode(email) + '&message=' + urlencode(interest) + ':%20' + urlencode(message);

    $.ajax({
      type:     "POST",
      url:      "/helpdesk/newticket.php",
      dataType: "text",
      data:     args,
      error:    submit_handleError,
      success:  submit_handleResponse
    });
  }
}

// same functions as in homepage.js
function submit_handleResponse(xml)
{
  $('#frm-submit').html("<h3>Thank you</h3><p>An email has been sent with information about this help ticket.  We will get back to you as soon as possible.</p>");
  $('#frm-info').hide();
}

function submit_handleError()
{
  say("Cannot connect to server. Please try again in a few moments.");
}

function say(msg, hi)
{
  //$('#submit_text').html(msg);
  alert(msg);
}
function validEmail(email)
{
  return /^([\w-]+(?:\.[\w-]+)*)@((?:[\w-]+\.)*\w[\w-]{0,66})\.([a-z]{2,6}(?:\.[a-z]{2})?)$/i.test(email);
}
function urlencode(str)
{
  return escape(str)
    .replace(/\+/g,'%2B')
    .replace(/\s/g, '+')
    .replace(/\*/g, '%2A')
    .replace(/\//g, '%2F')
    .replace(/@/g, '%40');
}

String.prototype.trim = function()
{
  return this.replace(/^\s+|\s+$/g,"");
}
